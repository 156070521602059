@import "./styles/antd-colors.less";
@import "./styles/antd-custom-theme.less";

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Oswald", sans-serif;
}

//////////////
// Input style
//////////////
.wr-styled-input {
  padding: 0 10px;

  .ant-form-item-label {
    display: block;
    width: 100%;
    text-align: left;
  }
  .ant-form-item-control {
    display: block;
    width: 100%;
    .ant-input-affix-wrapper {
      border: none;
      border-bottom: 1px solid black;
      background-color: transparent;
      padding-bottom: 5px;
      border-radius: 0;

      .ant-input-prefix {
        margin-right: 10px;
      }
      input {
        background-color: transparent;
      }

      &:focus,
      &:hover,
      &-focused {
        border-color: transparent;
        box-shadow: none;
        border-bottom: 1px solid black;
      }
    }
  }
}

///////////////
// Acion Select
///////////////
.wr-action-select-wrapper {
  margin-bottom: 20px;
  display: block;

  button {
    margin-left: 10px;
  }
}

///////////////
// Result style
///////////////
.wr-result-wrapper {
  display: block;
  width: 100%;

  .wr-result {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    &-icon {
      margin-right: 20px;
      svg {
        width: 70px;
        height: 70px;
      }

      &-success svg {
        fill: @primary-color;
      }
      &-info svg {
        fill: @info-color;
      }
      &-warning svg {
        fill: @warning-color;
      }
      &-danger svg {
        fill: @error-color;
      }
    }
    .ant-typography {
      font-size: @heading-3-size;
      margin-bottom: 0 !important;
      &-info {
        color: @info-color;
      }
    }
    &-extras-inner {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
  }
}
