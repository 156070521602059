@import "../styles/antd-custom-theme.less";

.ant-layout {
  background: @white;
}
.base-sider {
  min-height: calc(100vh - 40px);
  margin: 20px;

  .base-menu {
    background-color: inherit;
    text-transform: uppercase;
  }
}
// Menu overrides
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) {
  .ant-menu-item {
    font-family: "Oswald", sans-serif;
    font-weight: 500;
    font-size: @font-size-lg;

    &-selected {
      background-color: inherit;
      color: @primary-color;
    }
  }
}

.base-layout {
  margin: 20px;

  .base-header {
    background-color: #fff;
    text-align: right;
    text-transform: uppercase;

    &-profile-link {
      display: inline-block;
      .ant-avatar {
        margin-right: 10px;
        background-color: @purple-base;
      }
    }
  }
}
