@import "../../styles/antd-custom-theme.less";

.ant-card {
  width: calc(100% - 40px);
  margin: 0 20px 50px 20px;
  &-head {
    border-bottom: none;
    padding-left: 0;
    padding-right: 0;

    &-wrapper {
      overflow: hidden;
      .ant-card-head-title {
        position: relative;
        display: inline-block;
        padding-bottom: 10px;
        overflow: initial;

        .ant-typography:not(.title-with-link) {
          &::after {
            content: " ";
            display: inline-block;
            height: 1px;
            text-shadow: none;
            background-color: @black;
            width: 100%;
            line-height: inherit;
            vertical-align: super;
            margin-left: 5px;
          }
        }
        .title-with-link {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            width: 100%;
            margin-bottom: 0;
          }

          span {
            align-self: center;
            padding-left: 10px;
            background-color: @white;
            a {
              font-family: "Oswald", sans-serif;
              color: @text-color;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
  &-body {
    background-color: @normal-color;
  }
}
