@font-face {
  font-family: 'CamingoDosOffice-Italic';
  src: url('CamingoDosOffice-Italic.eot');
  src: url('CamingoDosOffice-Italic.eot?#iefix') format('embedded-opentype'),
       url('CamingoDosOffice-Italic.svg#CamingoDosOffice-Italic') format('svg'),
       url('CamingoDosOffice-Italic.ttf') format('truetype'),
       url('CamingoDosOffice-Italic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

