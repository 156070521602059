@import "../styles/antd-custom-theme.less";

main.auth-layout {
  width: 100%;
  height: 100vh;
  padding: 20px;

  .ant-row {
    height: 100%;
    /* todo: change backgound image */
    .cover-image {
      background-image: url("https://images.unsplash.com/photo-1488590528505-98d2b5aba04b?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dGVjaG5vbG9neXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60");
      background-repeat: no-repeat;
      background-size: cover;
    }

    .auth-form {
      &-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .auth-form-title {
          font-size: @heading-1-size;
          display: block;
          margin-bottom: 10px;
        }
        .ant-typography {
          margin-bottom: 30px;
        }
        form {
          width: 360px;

          .form-check {
            margin-bottom: 5px;
          }

          button[type="submit"] {
            margin-top: 19px;
            width: 100%;
            padding-top: 10px;
            padding-bottom: 10px;
            height: auto;
          }
        }
      }
    }
  }
}

.confirm-account {
  &-loader {
    margin: 0 auto;
    display: block;
  }
  &-inner {
    .confirm-state {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      margin: 50px 0;
    }
  }
  .anticon.confirm {
    svg {
      width: 100px;
      height: 100px;
      margin-right: 30px;
    }

    &.success svg {
      fill: @primary-color;
    }

    &.warning svg {
      fill: @warning-color;
    }
  }
  .ant-typography {
    font-size: @heading-3-size;
  }
  > button {
    display: block;
    margin: 0 auto;
  }
}
