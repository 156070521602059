@font-face {
  font-family: 'CamingoDos-Light';
  src: url('CamingoDos-Light.eot');
  src: url('CamingoDos-Light.eot?#iefix') format('embedded-opentype'),
       url('CamingoDos-Light.svg#CamingoDos-Light') format('svg'),
       url('CamingoDos-Light.ttf') format('truetype'),
       url('CamingoDos-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

