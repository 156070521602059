@import "../../../styles/antd-custom-theme.less";

.applicant-tasklist {
  list-style: none;
  counter-reset: mycounter;
  padding: 0;
  .task-item {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 30px 0 25px 40px;
    padding: 0 0 0 10px;

    &-inner {
      &-ac {
        h4 {
          margin-top: 10px;
        }
      }
    }

    > span {
      margin-left: 20px;
      display: block;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: counter(mycounter);
      counter-increment: mycounter;
      position: absolute;
      top: 25px;
      transform: translateY(-50%);
      left: -40px;
      width: 35px;
      height: 35px;
      line-height: 34px;
      border-radius: 50%;
      color: @text-color;
      background: @white;
      text-align: center;
      margin-right: 10px;
      font-family: "Oswald", sans-serif;
      font-size: @font-size-sm;
    }
  }
}
