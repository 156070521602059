@font-face {
  font-family: 'CamingoDosOffice-BoldItalic';
  src: url('CamingoDosOffice-BoldItalic.eot');
  src: url('CamingoDosOffice-BoldItalic.eot?#iefix') format('embedded-opentype'),
       url('CamingoDosOffice-BoldItalic.svg#CamingoDosOffice-BoldItalic') format('svg'),
       url('CamingoDosOffice-BoldItalic.ttf') format('truetype'),
       url('CamingoDosOffice-BoldItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

