@import "../../styles/antd-custom-theme.less";

.onboarding-modal {
  .ant-modal-content {
    min-height: 90vh;
    .ant-modal-header {
      border-bottom: none;
      text-align: center;

      .onboarding-logo {
        max-width: 400px;
        margin: 30px 0;
      }
    }
    .ant-modal-footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      clear: both;
    }
  }
}
