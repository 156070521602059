@import "../../styles/antd-custom-theme.less";

.service-item-wrapper {
  .service-item {
    text-align: center;
    a {
      display: inline-block;
      width: 100%;
      height: 100%;
      padding: 5px;
      background-color: @white;
      border-radius: 5px;

      .service-item-inner-img {
        width: 100%;
        img {
          max-width: 50px;
          max-height: 50px;
          display: block;
          margin: 0 auto;
        }
      }
    }

    &-inactive {
      text-align: center;
      a {
        pointer-events: none;
        background-color: transparent;
        .service-item-inner-img {
          img {
            filter: grayscale(100%);
            margin: 0 auto;
          }
        }
      }
    }
  }
}
