@import "../../../styles/antd-custom-theme.less";

.ap-card {
  .contact-details {
    display: block;
    width: 100%;

    .contact-person {
      &-title {
        margin-bottom: 0;
        line-height: 1;
      }

      ul {
        margin-top: 10px;
        list-style-type: none;

        li {
          margin: 5px 0;
        }
      }
    }

    &:not(:first-child) {
      border-top: 1px solid lighten(@black, 80%);
      padding-top: 20px;
      margin-top: 20px;
    }
  }
}
